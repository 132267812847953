<template>
  <div class="notice-wrap">
    <NoticeBar
      left-icon="volume"
      background="var(--bg)"
      color="var(--theme)"
      :text="content"
    />
  </div>
</template>

<script>
import { Icon, NoticeBar } from "vant";
import { $get, dealError } from "@/utils";
export default {
  components: {
    Icon,
    NoticeBar,
  },
  data: () => ({
    content: "",
  }),
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const {
          data: { scroll_notice },
        } = await $get("/other/config", {
          act: "system",
        });
        this.content = scroll_notice || "";
      } catch (error) {
        dealError(error)
      }
    },
  },
};
</script>

<style lang="less" scoped>
.notice-wrap {
  /deep/ .van-notice-bar {
    padding: 3px 6px;
    border-radius: 6px;
    height: calc(22rem / 16);
  }
}
</style>